.userinfo-modal-div .modal-content {
  background-color: #eff1f3;
  margin: auto;
  padding-top: 20px;
  border: 1px solid #888;
  width: 550px;
  border-radius: 5px;
}
.userinfo-modal-div .modal-header .userLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: rgb(0, 49, 98);
  border-top: 2px solid transparent;
  width: 33.33%;
  padding-bottom: 12px;
  font-size: 15px;
  border-bottom-width: 4px;
  cursor: pointer;
}
login-register-div > .ui.attached.segment {
  border: none;
  width: calc(100% - (1px * 2));
  margin: 0px;
}
login-register-div > .footer {
  border: none;
  box-shadow: none;
}
.userinfo-modal-div .modal-header .tabActive {
  border-bottom: 4px solid rgb(219, 40, 40);
  color: rgb(219, 40, 40);
}
.userinfo-modal-div form {
  margin-bottom: 14px;
}
input[type="text"] {
  text-align: left !important;
}
.dbBtnSubmit,
.dbBtnLogout {
  width: 100%;
  height: 50px;
  margin-top: 10px;
}
.userinfo-modal-div .close {
  color: #aaaaaa;
  float: right;
  font-size: 35px;
  font-weight: bold;
  margin-top: -27px;
  margin-right: -21px;
}
.userinfo-modal-div .close:hover {
  color: #eee;
}
.userinfo-modal-div .login-register-div {
  border: none;
}
.close-div {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: "#555555";
  margin-right: 10px;
  margin: 0 20px 20px 20px;
}
.userinfo-modal-div .modal-header {
  margin: 30px;
  border-radius: 5px;
}
.userinfo-modal-div .white-bg {
  background-color: #fff;
  min-height: 500px;
  border: 1px solid #ddd;
}

.ui.list > .item {
  /* border-bottom: 1px solid#979faf; */
  margin-top: 3px;
}
.ui.list > .item:last-child {
  border-bottom: none;
}
input.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.goorder-online-category {
  font-weight: 700 !important;
  padding: 12px !important;
  margin: 0 !important;
  font-size: 14px !important;
  font-family: "Rubik", sans-serif;
  color: rgb(118, 118, 118);
}

.goorder-online-category:hover {
  cursor: pointer !important;
}

.userInfoTab {
  position: relative !important;
  min-height: 0px !important;
  max-height: calc(100vh - 32px) !important;
  max-width: 520px !important;
  width: calc(100vw - 32px) !important;
  display: flex !important;
  flex-direction: column !important;
  background: rgb(255, 255, 255) !important;
  overflow: hidden !important;
  margin: auto !important;
}

.userInfoTab .header {
  margin: 0px !important;
}

.userInfoTab .item-info {
  padding: 12px !important;
  line-height: 25px !important;
}

.userInfoTab .item-name {
  font-size: 22px !important;
  margin: 0 !important;
  font-weight: 700 !important;
  height: 25px !important;
}

.userInfoTab .item-discription {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(120, 120, 120);
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 15px 12px;
  padding: 0px;
  max-height: 100px;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.userInfoTab .item-image {
  width: 100%;
  max-height: 300px;
  margin: "0px 0";
}

.userInfoTab .item-list {
  max-height: calc(85vh - 190px) !important;
  overflow-y: auto !important;
}

.userInfoTab .footer {
  margin: 0px !important;
  padding: 0px !important;
  height: 120px !important;
  border: none !important;
  box-shadow: none !important;
}
/* small screen phone */
@media only screen and (max-width: 1024px) {
  .userinfo-modal-div .modal-content {
    width: 360px;
    border-radius: 5px;
  }
  .userinfo-modal-div .user-modal-content {
    width: 100%;
    border-radius: 5px;
  }
  .google_button {
    margin-left: 10px !important;
  }
  #rc-anchor-container {
    width: 300px;
  }
  .rc-anchor-normal {
    width: 270px !important;
  }
  .userinfo-modal-div .modal-header .userLabel {
    font-size: 15px;
    font-weight: 300;
  }
  .userInfoTab {
    position: relative !important;
    min-height: 0px !important;
    max-width: 500px !important;
    display: flex !important;
    flex-direction: column !important;
    background: rgb(255, 255, 255) !important;
  }

  .userInfoTab .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .userInfoTab .header {
    margin: 0px !important;
  }

  .userInfoTab .item-info {
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
  }

  .userInfoTab .item-name {
    font-size: 22px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .userInfoTab .item-option-name {
    font-size: 16px !important;
  }

  .userInfoTab .item-list {
    overflow-y: auto !important;
  }

  .userInfoTab .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 120px !important;
    border: none !important;
    box-shadow: none !important;
  }

  .userInfoTab input {
    text-align: left !important;
  }
}
@media only screen and (max-width: 736px) {
  .checkoutUsderIcon {
    float: right;
    margin-right: 0px !important;
  }
  .userLabel {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 10px !important;
    font-weight: 600;
    text-align: center;
    color: rgb(0, 49, 98) !important;
    width: 33.33%;

    font-size: 15px;
    border-bottom: none;
    cursor: pointer;
  }
  .ui.attached.segment {
    border: none;
    width: calc(100% - (1px * 2));
    margin: 20px 1px;
  }

  .tabActive {
    color: rgb(219, 40, 40) !important;
    border-top: none;
    border-bottom: 4px solid rgb(219, 40, 40);
  }
  .usertab-list-icon {
    overflow: auto !important;
    white-space: nowrap !important;
    display: inline-block !important;
    width: calc(100% - 60px) !important;
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
  }
  .userinfo-modal-div .white-bg {
    margin: 2px;
    border: none;
  }
  .userinfo-tab {
    width: calc(100% - 30px) !important;
  }
  .ui.attached.segment {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .ui.input.leftAlign {
    text-align: left !important;
  }
  .userInfoTab {
    position: relative !important;
    height: 100% !important;
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(255, 255, 255) !important;
    margin: 0 !important;
    padding: 0 !important;
    max-height: unset !important;
  }

  .userInfoTab .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .userInfoTab .item-image {
    width: 100%;
    max-height: 200px;
    margin: "0px 0";
  }

  .userInfoTab .header {
    margin: 0px !important;
  }

  .userInfoTab .item-info {
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .userInfoTab .item-name {
    font-size: 19px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .userInfoTab .item-option-name {
    font-size: 16px !important;
  }

  .userInfoTab .item-list {
    max-height: calc(100vh - 120px) !important;
    overflow-y: auto !important;
    top: 50px !important;
    position: relative !important;
  }

  .userInfoTab .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 70px !important;
    bottom: 0 !important;
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%) !important;
  }

  .userInfoTab input {
    text-align: left !important;
  }
  .dbBtnSubmit {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
    float: left;
  }
  .dbBtnLogout {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
    float: right;
  }

  .userinfo-modal-div .close {
    margin-top: 6px;
    position: absolute;
    right: 35px;
    font-size: 25px;
  }
}
