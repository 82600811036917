@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
.login-modal-div .modal-content {
  background-color: #eff1f3;
  margin: auto;
  padding-top: 20px;
  border: 1px solid #888;
  width: 550px;
  border-radius: 5px;
}
.login-modal-div .modal-header .tabLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border-top: 2px solid transparent;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .modal-header .userLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border-top: 2px solid transparent;
  width: 33%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.ui.attached.segment {
  border: none;
  width: calc(100% - (1px * 2));
  margin: 0px;
  max-height: 700px;
  overflow-y: auto;
}
.login-modal-div .modal-header .tabActive {
  color: #555;
  border-top: 2px solid #003162;
  border-bottom: 3px solid #fff;
  background-color: #fff;
}
.login-register-div {
  border: 1px solid #ddd;
}
.login-register-div .active {
  color: #555;

  border-top: 2px solid #003162;
  border-bottom: 3px solid #fff;
  background-color: #fff;
}
.login-modal-div .modal-header input[type="radio"] {
  display: none;
}
.login-modal-div .modal-header label.tab-label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  padding-bottom: 15px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border: 1px solid transparent;
  border-bottom-width: 1px;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .modal-header #tab1:checked ~ #content1,
.login-modal-div .modal-header #tab2:checked ~ #content2 {
  display: block;
}
.login-modal-div form {
  margin-bottom: 14px;
}
input[type="color"],
input[type="file"],
input[type="hidden"],
input[type="image"],
input[type="radio"] {
  border: 0;
  border-radius: 0;
  padding: 0;
}
.modal-form-group {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
.modal-form-group .input-icon {
  opacity: 1;
  position: absolute;
  z-index: 1;
  left: 8px;
  top: 10px;
  color: #979faf;
  font-size: 26px;
}
.login-modal-div input[type="text"],
.login-modal-div input[type="email"],
.login-modal-div input[type="password"] {
  border: 1px solid #c2c7d0;
  border-top-color: rgb(194, 199, 208);
  border-right-color: rgb(194, 199, 208);
  border-bottom-color: rgb(194, 199, 208);
  border-left-color: rgb(194, 199, 208);
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(151, 159, 175, 0.1),
    inset 0 1px 15px rgba(151, 159, 175, 0.05);
  box-sizing: border-box;
  color: #39424e;
  display: inline-block;
  line-height: 1.5em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 7px 10px;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 100%;
}
.login-modal-div .modal-header label.tab-label:first-of-type {
  float: left;
}
.login-modal-div .modal-header label.tab-label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border: 1px solid transparent;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -5px;
}
.login-modal-div .modal-header .tabLabel:hover {
  color: #888;
  cursor: pointer;
}
.close-div {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: "#555555";
  margin-right: 10px;
  margin: 0 20px 20px 20px;
}
.login-modal-div .center {
  text-align: center;
}
.login-modal-div .modal-header {
  padding: 20px;
  border-radius: 5px;
}
.login-modal-div .white-bg {
  background-color: #fff;
}
.g_id_signin {
  display: inline-block !important;
  background: white !important;
  color: #c9453a !important;
  border-radius: 5px !important;
  border: thin solid #888 !important;
  white-space: nowrap;
}
span.icon {
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
.social-divider {
  width: 80%;
  text-align: center;
  border-bottom: 1px solid #979faf;
  line-height: 0.1em;
  margin: 20px 0 20px;
  margin-left: 10%;
}
.g_id_signin {
  margin-top: "5px";
  margin-left: "20px";
}
.auth-input-field {
  text-align: left !important;
  font-size: 15px !important;
}
.ui.form .field > label {
  font-size: 15px !important;
}

@media only screen and (min-width: 1024px) {
  .checkoutUsderIcon {
    position: absolute;
    right: 100px;
  }
}
@media only screen and (min-width: 1481px) {
  .checkoutUsderIcon {
    position: absolute;
    right: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .login-modal-div {
    max-width: 600px;
  }
  .login-modal-div .modal-content {
    width: 360px;
    border-radius: 5px;
  }
  .login-modal-div .user-modal-content {
    width: 100%;
    border-radius: 5px;
  }
  .google_button {
    margin-left: 10px !important;
  }
  #rc-anchor-container {
    width: 300px;
  }
  .rc-anchor-normal {
    width: 270px !important;
  }
}

.userinfo-modal-div .modal-content {
  background-color: #eff1f3;
  margin: auto;
  padding-top: 20px;
  border: 1px solid #888;
  width: 550px;
  border-radius: 5px;
}
.userinfo-modal-div .modal-header .userLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: rgb(0, 49, 98);
  border-top: 2px solid transparent;
  width: 33.33%;
  padding-bottom: 12px;
  font-size: 15px;
  border-bottom-width: 4px;
  cursor: pointer;
}
login-register-div > .ui.attached.segment {
  border: none;
  width: calc(100% - (1px * 2));
  margin: 0px;
}
login-register-div > .footer {
  border: none;
  box-shadow: none;
}
.userinfo-modal-div .modal-header .tabActive {
  border-bottom: 4px solid rgb(219, 40, 40);
  color: rgb(219, 40, 40);
}
.userinfo-modal-div form {
  margin-bottom: 14px;
}
input[type="text"] {
  text-align: left !important;
}
.dbBtnSubmit,
.dbBtnLogout {
  width: 100%;
  height: 50px;
  margin-top: 10px;
}
.userinfo-modal-div .close {
  color: #aaaaaa;
  float: right;
  font-size: 35px;
  font-weight: bold;
  margin-top: -27px;
  margin-right: -21px;
}
.userinfo-modal-div .close:hover {
  color: #eee;
}
.userinfo-modal-div .login-register-div {
  border: none;
}
.close-div {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: "#555555";
  margin-right: 10px;
  margin: 0 20px 20px 20px;
}
.userinfo-modal-div .modal-header {
  margin: 30px;
  border-radius: 5px;
}
.userinfo-modal-div .white-bg {
  background-color: #fff;
  min-height: 500px;
  border: 1px solid #ddd;
}

.ui.list > .item {
  /* border-bottom: 1px solid#979faf; */
  margin-top: 3px;
}
.ui.list > .item:last-child {
  border-bottom: none;
}
input.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
  box-shadow: none;
}
.goorder-online-category {
  font-weight: 700 !important;
  padding: 12px !important;
  margin: 0 !important;
  font-size: 14px !important;
  font-family: "Rubik", sans-serif;
  color: rgb(118, 118, 118);
}

.goorder-online-category:hover {
  cursor: pointer !important;
}

.userInfoTab {
  position: relative !important;
  min-height: 0px !important;
  max-height: calc(100vh - 32px) !important;
  max-width: 520px !important;
  width: calc(100vw - 32px) !important;
  display: flex !important;
  flex-direction: column !important;
  background: rgb(255, 255, 255) !important;
  overflow: hidden !important;
  margin: auto !important;
}

.userInfoTab .header {
  margin: 0px !important;
}

.userInfoTab .item-info {
  padding: 12px !important;
  line-height: 25px !important;
}

.userInfoTab .item-name {
  font-size: 22px !important;
  margin: 0 !important;
  font-weight: 700 !important;
  height: 25px !important;
}

.userInfoTab .item-discription {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(120, 120, 120);
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 15px 12px;
  padding: 0px;
  max-height: 100px;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.userInfoTab .item-image {
  width: 100%;
  max-height: 300px;
  margin: "0px 0";
}

.userInfoTab .item-list {
  max-height: calc(85vh - 190px) !important;
  overflow-y: auto !important;
}

.userInfoTab .footer {
  margin: 0px !important;
  padding: 0px !important;
  height: 120px !important;
  border: none !important;
  box-shadow: none !important;
}
/* small screen phone */
@media only screen and (max-width: 1024px) {
  .userinfo-modal-div .modal-content {
    width: 360px;
    border-radius: 5px;
  }
  .userinfo-modal-div .user-modal-content {
    width: 100%;
    border-radius: 5px;
  }
  .google_button {
    margin-left: 10px !important;
  }
  #rc-anchor-container {
    width: 300px;
  }
  .rc-anchor-normal {
    width: 270px !important;
  }
  .userinfo-modal-div .modal-header .userLabel {
    font-size: 15px;
    font-weight: 300;
  }
  .userInfoTab {
    position: relative !important;
    min-height: 0px !important;
    max-width: 500px !important;
    display: flex !important;
    flex-direction: column !important;
    background: rgb(255, 255, 255) !important;
  }

  .userInfoTab .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .userInfoTab .header {
    margin: 0px !important;
  }

  .userInfoTab .item-info {
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
  }

  .userInfoTab .item-name {
    font-size: 22px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .userInfoTab .item-option-name {
    font-size: 16px !important;
  }

  .userInfoTab .item-list {
    overflow-y: auto !important;
  }

  .userInfoTab .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 120px !important;
    border: none !important;
    box-shadow: none !important;
  }

  .userInfoTab input {
    text-align: left !important;
  }
}
@media only screen and (max-width: 736px) {
  .checkoutUsderIcon {
    float: right;
    margin-right: 0px !important;
  }
  .userLabel {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 10px !important;
    font-weight: 600;
    text-align: center;
    color: rgb(0, 49, 98) !important;
    width: 33.33%;

    font-size: 15px;
    border-bottom: none;
    cursor: pointer;
  }
  .ui.attached.segment {
    border: none;
    width: calc(100% - (1px * 2));
    margin: 20px 1px;
  }

  .tabActive {
    color: rgb(219, 40, 40) !important;
    border-top: none;
    border-bottom: 4px solid rgb(219, 40, 40);
  }
  .usertab-list-icon {
    overflow: auto !important;
    white-space: nowrap !important;
    display: inline-block !important;
    width: calc(100% - 60px) !important;
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
  }
  .userinfo-modal-div .white-bg {
    margin: 2px;
    border: none;
  }
  .userinfo-tab {
    width: calc(100% - 30px) !important;
  }
  .ui.attached.segment {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .ui.input.leftAlign {
    text-align: left !important;
  }
  .userInfoTab {
    position: relative !important;
    height: 100% !important;
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(255, 255, 255) !important;
    margin: 0 !important;
    padding: 0 !important;
    max-height: unset !important;
  }

  .userInfoTab .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .userInfoTab .item-image {
    width: 100%;
    max-height: 200px;
    margin: "0px 0";
  }

  .userInfoTab .header {
    margin: 0px !important;
  }

  .userInfoTab .item-info {
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .userInfoTab .item-name {
    font-size: 19px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .userInfoTab .item-option-name {
    font-size: 16px !important;
  }

  .userInfoTab .item-list {
    max-height: calc(100vh - 120px) !important;
    overflow-y: auto !important;
    top: 50px !important;
    position: relative !important;
  }

  .userInfoTab .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 70px !important;
    bottom: 0 !important;
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%) !important;
  }

  .userInfoTab input {
    text-align: left !important;
  }
  .dbBtnSubmit {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
    float: left;
  }
  .dbBtnLogout {
    width: calc(50% - 20px) !important;
    margin: 10px !important;
    float: right;
  }

  .userinfo-modal-div .close {
    margin-top: 6px;
    position: absolute;
    right: 35px;
    font-size: 25px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"; */
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.header {
  /* font-family: -apple-system, BlinkMacSystemFont, San Francisco, Helvetica Neue,
    Helvetica, Ubuntu, Roboto, Noto, Segoe UI, Arial, sans-serif !important; */
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  /* font-family: "Rubik", sans-serif; */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.sumi-global-btn-color {
  background-color: #003162 !important;
  /* background-color: #eb6123 !important; */
  color: #fff !important;
  /* border: 2px solid #003162 !important; */
  text-shadow: none !important;
  background-image: none !important;
  vertical-align: top !important;
  /* border: 1px solid black !important; */
}

.goorder-online-category {
  /* display: block !important; */
  /* float: right !important; */
  font-weight: 700 !important;
  padding: 12px !important;
  margin: 0 !important;
  font-size: 14px !important;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important; */
  font-family: "Rubik", sans-serif;
  color: rgb(118, 118, 118);
  /* font-size: 17px !important; */
  /* border-bottom: 1px none grey; */
}

.goorder-online-category:hover {
  cursor: pointer !important;
  /* border-bottom: 1px solid grey !important; */
  /* transition: border-bottom 250ms ease-in-out 0s !important; */
}

.goorder-online-itemDetail {
  position: relative !important;
  min-height: 0px !important;
  max-height: calc(100vh - 32px) !important;
  max-width: 520px !important;
  width: calc(100vw - 32px) !important;
  display: flex !important;
  flex-direction: column !important;
  background: rgb(255, 255, 255) !important;
  /* border-radius: 16px !important; */
  overflow: hidden !important;
  margin: auto !important;
}

.goorder-online-itemDetail .header {
  /* height: 70px !important; */
  margin: 0px !important;
}

.goorder-online-itemDetail .item-info {
  padding: 12px !important;
  line-height: 25px !important;
}

.goorder-online-itemDetail .item-name {
  font-size: 22px !important;
  margin: 0 !important;
  font-weight: 700 !important;
  height: 25px !important;
}

.goorder-online-itemDetail .item-discription {
  font-size: 14px;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"; */
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(120, 120, 120);
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 15px 12px;
  padding: 0px;
  max-height: 100px;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.goorder-online-itemDetail .item-image {
  width: 100%;
  max-height: 300px;
  margin: "0px 0";
}

.goorder-online-itemDetail .item-list {
  max-height: calc(85vh - 190px) !important;
  overflow-y: auto !important;
}

.goorder-online-itemDetail .item-option {
  padding: 2px 35px !important;
}

.goorder-online-itemDetail .footer {
  margin: 0px !important;
  padding: 0px !important;
  height: 70px !important;
}

/* .input input {
  text-align: center !important;
  padding: 0 !important;
} */

.inline {
  display: inline !important;
}

.dimmer.inverted {
  background-color: white !important;
}

.no-margin {
  margin: 0 !important;
}

/* .segment.inverted {
  background: rgb(0, 49, 98) !important;
  color: rgba(255, 255, 255, 0.9) !important;
} */

.menu-item {
  height: 135px !important;
  margin: 5px !important;
  display: inline-block !important;
  vertical-align: top !important;
  padding: 0 !important;
}

.menu-item .item_name {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  display: block;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 8px 0;
  padding: 0px;
  overflow: hidden;
}

.menu-item:hover {
  cursor: pointer !important;
  border-color: #b9b9b9 !important;
  transition: border-color 250ms ease-in-out 0s !important;
}

.menu-item-list {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 20px !important;
  width: calc(100% - 200px) !important;
  position: relative !important;
  margin: 10px 0 !important;
}

.menu-item-list .items-by-category {
  margin-bottom: 20px;
  padding-top: 70px;
  margin-top: -70px;
  z-index: 0;
}

.menu-item-list .items-by-category .category_name_availablity {
  font-size: small;
  margin: 0 10px;
  color: #a5643a;
}

.menu-item-list .items-by-category .category_name_availablity i {
  margin: 0 5px !important;
}

.category-scrolling-bar {
  display: none !important;
}

.category-list-icon {
  display: none !important;
}

.menu-category-list {
  width: 200px !important;
  height: calc(100vh - 90px) !important;
  overflow-y: hidden !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 70px !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 17px !important;
  color: rgb(118, 118, 118) !important;
}

.menu-category-list .menu-category {
  overflow-y: auto !important;
  height: calc(100% - 30px) !important;
}

/* ------------------------------ check out -------------------------- */
.checkout-panel {
  height: 100% !important;
  width: 100% !important;
  display: relative !important;
}

.checkout-panel .content {
  /* height: 100% !important; */
  width: 700px !important;
  /* overflow: hidden !important; */
  padding-top: 60px;
  margin: 20px auto;
  margin-top: -40px;
  display: flex;
}

.checkout-panel .content .header {
  min-width: 150px !important;
}

.checkout-panel .content .method {
  width: calc(100% - 150px) !important;
  text-align: left !important;
}

/* .checkout-panel .content .method .field {
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}
.checkout-panel .content .method .field:hover {
  cursor: pointer !important;
} */

.checkout-panel .content .time {
  width: calc(100% - 150px) !important;
  text-align: left !important;
}

.checkout-panel .content .info {
  width: calc(100% - 150px);
  text-align: left !important;
}

/* .checkout-panel .content .time .field {
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}
.checkout-panel .content .time .field:hover {
  cursor: pointer !important;
} */

.checkout-panel .content .item-list {
  /* max-width: calc(100% - 150px) !important; */
  width: calc(100% - 150px) !important;
  /* font-family: "Rubik", sans-serif !important; */
}
/* 
.checkout-panel .content .address {
  width: calc(100% - 150px) !important;
  height: 200px !important;
} */

.checkout-panel .content .google-map {
  width: 100% !important;
  position: relative !important;
  overflow: hidden !important;
  /* min-height: 250px !important; */
}
/* 
.checkout-panel .content .address .google-map-address {
  width: 100% !important;
  height: 50px !important;
} */

.merchant-name {
  font-size: 33px !important;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;

  /* font-family: "Rubik", sans-serif !important; */
}

.merchant-info {
  font-size: 14px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: rgb(118, 118, 118);
  display: block;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.merchant-info i {
  color: rgb(0, 49, 98) !important;
  /* color: rgb(165, 100, 58) !important; */
}

orderMethod_select {
  font-size: 14px;
  font-family: "Rubik", sans-serif !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: rgb(118, 118, 118);
  display: block;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  margin: 0px;
  padding: 0px;
}

.merchant-sidebar {
  width: 100px !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  position: fixed !important;
  background-color: #1b1c1d !important;
  border-radius: unset !important;
  box-shadow: none !important;
  border-width: 0 !important;
}

.merchant-sidebar .item {
  cursor: pointer !important;
  border-radius: unset !important;
  height: 100px !important;
}

.merchant-sidebar .icon1 {
  font-size: 16px !important;
}

.merchant-sidebar .exit {
  position: fixed !important;
  width: 100px !important;
  height: 100px !important;
  padding: 0 20px !important;
  top: calc(100% - 100px) !important;
  color: white !important;
  cursor: pointer !important;
}

.merchant-content {
  height: calc(100%) !important;
  width: calc(100% - 100px) !important;
  position: fixed !important;
  left: 100px !important;
}

.order-recall-sidebar {
  width: 400px !important;
  height: 100% !important;
}

.order-recall-sidebar .item {
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.order-recall-sidebar .date-filter {
  position: relative !important;
  width: calc(100%) !important;
  margin: 10px 0 0 0 !important;
  padding: 7px 2px !important;
  font-size: 14px !important;
}

.order-recall-detail {
  width: calc(100% - 400px) !important;
  margin: 0 !important;
  padding: 0 !important;
  height: calc(100%) !important;
  overflow: hidden !important;
}

.order-recall-detail .detail-list {
  margin: 0 !important;
  height: calc(100% - 160px) !important;
  width: 100% !important;
  overflow-y: auto !important;
  /* direction: rtl !important; */
  padding: 30px !important;
}

.homepage-businessinfo {
  display: flex !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 20px 0px !important;
  z-index: 1;
}

.homepage-businessinfo .businessinfo {
  margin-top: 10px !important;
  width: 100% !important;
}

.homepage-businessinfo .logoImg {
  display: inline !important;
}

.homepage-businessinfo .logoImg img {
  display: inline !important;
  object-fit: cover !important;
  opacity: 0.7 !important;
  float: right !important;
}

/* -------------------------------- order summery panel ----------------------------- */

/* .order-summery.forms .orderSummeryErrorMsg { */
.orderSummeryErrorMsg {
  text-align: center !important;
  position: fixed !important;
  top: 49px !important;
  width: calc(100vw) !important;
  right: 0 !important;
  z-index: 1 !important;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset,
    0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
}

.items-by-category .category-discription {
  font-size: 12px;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"; */
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(120, 120, 120);
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 35px;
  white-space: normal;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.menu-item .item-discription {
  font-size: 12px;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"; */
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(120, 120, 120);
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  max-width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 35px;
  white-space: normal;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.menu-item .item-discription:hover {
  color: rgb(25, 25, 25) !important;
  transition: color 0.15s ease-in-out 0s !important;
}

.menu-item .item_price {
  font-size: 14px;
  /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"; */
  /* font-family: "Roboto", sans-serif !important; */
  font-family: "Rubik", sans-serif !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(73, 73, 73);
  display: block;
  font-feature-settings: "liga", "clig off";
  font-variant-ligatures: no-common-ligatures;
  margin: 7px 0 0 0;
  padding: 0px;
}

.ui.form .field > label {
  font-size: 12px !important;
}

/* ------------------------------- orderMethodSelectBtn ------------------------------ */
.orderMethodSelectBtn {
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  /* margin: 20px !important; */
  position: relative !important;
  background-color: white !important;
  opacity: 0.9 !important;
  width: 150px !important;
  height: 150px !important;
  vertical-align: top !important;
  padding: 15px !important;
}

/* ------------------------------- orderPlacedPanel -------------------------------------*/
.order-placed-panel .get-receipt-icon {
  margin-right: 0.5em !important;
}

.order-placed-panel .get-receipt-btn {
  display: block;
  float: right;
  font-size: 13px;
  color: rgb(0, 49, 98);
  cursor: pointer;
}

.order-placed-panel .order-info {
  font-size: 13px;
  color: #6d6d6d;
  font-weight: 600;
  display: block;
}

.order-placed-panel .warning-msg {
  background-color: yellow;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #6d6d6d;
}

/* large screen */
@media only screen and (min-width: 1480px) {
  .category-list-panel {
    position: fixed !important;
    right: 0 !important;
    top: 50px !important;
    height: calc(100vh - 50px) !important;
    width: 210px !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: rgb(253, 253, 253) !important;
    /* background-color: #fffaf6 !important; */
  }
  .shopping-cart-icon {
    display: none !important;
  }

  .shopping-cart-panel-overlay {
    display: none !important;
  }

  .shopping-cart-panel {
    position: fixed !important;
    right: 0 !important;
    top: 50px !important;
    height: calc(100vh - 50px) !important;
    width: 325px !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: rgb(253, 253, 253) !important;
    /* background-color: #fffaf6 !important; */
  }

  .shopping-cart-panel .close-icon {
    display: none !important;
    float: left !important;
    size: large !important;
  }

  .shopping-cart-panel .item-list {
    max-height: calc(100% - 80px) !important;
    overflow-y: auto !important;
  }

  .menu-select-panel {
    width: calc(100vw - 345px) !important;
    height: calc(100% - 50px) !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 50px !important;
    position: relative !important;
    z-index: 0 !important;
  }

  .menu-item {
    /* width: calc(33.3% - 10px) !important; */
    width: calc(50% - 10px) !important;
  }

  .orderSummeryErrorMsg {
    width: calc(100vw - 325px) !important;
    left: 0 !important;
  }
}

/* medium screen or small screen */
@media only screen and (max-width: 1480px) {
  .category-list-panel {
    z-index: 5 !important;
    position: fixed !important;
    right: 0 !important;
    top: 50px !important;
    height: calc(100vh - 50px) !important;
    width: 210px !important;
    margin: 0 !important;
    padding: 0 !important;
    display: none !important;

    background-color: rgb(253, 253, 253) !important;
    /* background-color: #fffaf6 !important; */

    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .blockdisplay-if-medium {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .shopping-cart-icon {
    display: inline-block !important;
    height: 40px !important;
    right: 5px !important;
    position: fixed !important;
  }

  .shopping-cart-panel-overlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    height: 100% !important;
    width: 100vw !important;
    z-index: 3 !important;
    background-color: black !important;
    opacity: 0.1 !important;
    transition-property: opacity !important;
    transition-duration: 0.2s !important;
    display: block !important;
  }

  .shopping-cart-panel {
    z-index: 5 !important;
    position: fixed !important;
    right: 0 !important;
    top: 50px !important;
    height: calc(100vh - 50px) !important;
    width: 325px !important;
    margin: 0 !important;
    padding: 0 !important;
    display: none !important;

    background-color: rgb(253, 253, 253) !important;
    /* background-color: #fffaf6 !important; */

    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .shopping-cart-panel .close-icon {
    display: inline-block !important;
    height: 20px !important;
  }

  .shopping-cart-panel .close-icon:hover {
    cursor: pointer !important;
  }

  .shopping-cart-panel .item-list {
    max-height: calc(100vh - 160px) !important;
    overflow-y: auto !important;
  }

  .menu-select-panel {
    width: calc(100vw) !important;
    height: calc(100% - 50px) !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 50px !important;
    position: relative !important;
    /* overflow-x: hidden !important; xmcai  */
  }

  .menu-item {
    width: calc(50% - 10px) !important;
    margin: 5px 5px !important;
  }

  .menu-item-list {
    width: calc(100%) !important;
    margin: 0 !important;
  }

  .merchant-sidebar {
    width: 40px !important;
  }

  .merchant-sidebar .item {
    cursor: pointer !important;
    border-radius: unset !important;
    height: 50px !important;
    padding: 10px !important;
  }

  .merchant-sidebar .icon1 {
    font-size: 7px !important;
  }

  .merchant-sidebar .exit {
    position: fixed !important;
    width: 40px !important;
    height: 50px !important;
    padding: 0 10px !important;
    top: calc(100% - 60px) !important;
    color: white !important;
    cursor: pointer !important;
  }

  .merchant-content {
    width: calc(100% - 40px) !important;
    left: 40px !important;
  }

  .order-recall-sidebar {
    width: 290px !important;
    height: 100% !important;
  }

  .order-recall-sidebar .item {
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 7px !important;
  }

  .order-recall-sidebar .date-filter {
    font-size: 14px !important;
  }

  .order-recall-detail {
    width: calc(100% - 290px) !important;
  }

  .order-recall-detail .detail-list {
    margin: 0 !important;
    height: calc(100% - 160px) !important;
    overflow-y: auto !important;
    /* direction: rtl !important; */
    padding: 15px !important;
  }
}

/* small screen phone */
@media only screen and (max-width: 1024px) {
  .backToMenuBtn {
    display: none !important;
  }

  .homepage-businessinfo {
    flex-direction: column-reverse !important;
  }

  .homepage-businessinfo .businessinfo {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .homepage-businessinfo .logoImg {
    width: 100% !important;
    display: block !important;
  }

  .homepage-businessinfo .logoImg img {
    width: 30% !important;
    margin: 0 auto !important;
    object-fit: cover !important;
    opacity: 0.7 !important;
    display: block !important;
    float: unset !important;
  }

  .merchant-name {
    font-size: 30px !important;
  }

  .merchant-info {
    margin: 2px 0;
    font-size: small !important;
    font-weight: 500 !important;
    display: block !important;
  }

  .orderMethod_select {
    margin: 2px 0;
    font-size: small !important;
    font-weight: 500 !important;
    display: block !important;
    text-align: center !important;
  }

  .menu-item-list {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    width: calc(100%) !important;
    position: relative !important;
    margin: 0 0 !important;
  }

  .menu-item-list .items-by-category {
    margin-bottom: 20px;
    padding-top: 120px;
    margin-top: -120px;
  }

  .menu-item-list .items-by-category .category_name_availablity {
    margin: 0;
    display: block;
    font-size: 12px;
  }

  .menu-item {
    /* width: calc(100%) !important; */
    width: calc(50% - 10px) !important;
    margin: 5px 5px !important;
  }

  .category-scrolling-bar {
    display: block !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 49px !important;
    z-index: 1 !important;
    background-color: white !important;
    margin: 0 -1em !important;
    width: calc(100% + 2em) !important;
    height: 100% !important;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .category-list-icon {
    overflow: auto !important;
    white-space: nowrap !important;
    display: inline-block !important;
    width: calc(100% - 90px) !important;
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
    /* font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol" !important; */
    font-family: "Rubik", sans-serif !important;
    /* position: -webkit-sticky !important;
    position: sticky !important; 
    top: 49px !important;
    z-index: 1 !important;*/
  }

  .category-list-scroll-bar {
    overflow: auto !important;
    white-space: nowrap !important;
    display: inline-block !important;
    width: calc(100% - 10px) !important;
    background-color: white;
    margin: auto;
    padding: 0 !important;
    font-family: "Rubik", sans-serif !important;
  }
  .category-list-scroll-bar .category {
    display: inline-block !important;
    /* color: rgb(118, 118, 118) !important; */
    text-align: center !important;
    padding: 15px 10px !important;
    text-decoration: none !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 16px !important;
    letter-spacing: -0.07ch !important;
  }

  .category-scrolling-icon {
    /* position: absolute !important; */
    /* display: inline-block !important; */
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    width: 20px !important;
    background-color: white !important;
    transition: visibility 0s, opacity 0.5s linear !important;
    vertical-align: top !important;
    margin-top: 13px !important;
    /* color: rgb(181, 129, 5) !important; */
    color: rgb(175 175 175) !important;
  }

  .category-scrolling-icon.left {
    margin-right: 5px !important;
  }

  .category-scrolling-icon.right {
    margin-left: 5px !important;
  }

  .category-list-icon .category {
    display: inline-block !important;
    /* color: rgb(118, 118, 118) !important; */
    text-align: center !important;
    padding: 15px 10px !important;
    text-decoration: none !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 16px !important;
    letter-spacing: -0.07ch !important;
  }

  /* .category-list-icon {
    display: block !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 49px !important;
    z-index: 3 !important;
   
  } */

  .menu-category-list {
    display: none !important;
  }

  .goorder-online-itemDetail {
    position: relative !important;
    min-height: 0px !important;
    /* max-height: calc(100vh - 50px) !important; */
    max-width: 500px !important;
    display: flex !important;
    flex-direction: column !important;
    background: rgb(255, 255, 255) !important;
    /* border-radius: 16px !important; */
    /* overflow: hidden !important; */
  }

  .goorder-online-itemDetail .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .goorder-online-itemDetail .header {
    /* height: 70px !important; */
    margin: 0px !important;
  }

  .goorder-online-itemDetail .item-info {
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
  }

  .goorder-online-itemDetail .item-name {
    font-size: 22px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .goorder-online-itemDetail .item-option-name {
    font-size: 16px !important;
  }

  .goorder-online-itemDetail .item-list {
    /* max-height: calc(75vh - 290px) !important; */
    overflow-y: auto !important;
  }

  .goorder-online-itemDetail .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 70px !important;
  }

  .goorder-online-itemDetail input {
    text-align: center !important;
  }

  .ui.selection.dropdown .menu {
    max-height: 300px !important;
  }

  /* ------------------------------ check out -------------------------- */
  .checkout-panel {
    height: 100% !important;
    width: 100% !important;
    display: relative !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .checkout-panel .content {
    height: 100% !important;
    /* width: 350px !important; */
    width: 100% !important;
    /* overflow-y: hidden !important;
    overflow-x: hidden !important; */
    display: block !important;
    font-family: Rubik, sans-serif !important;
  }

  .checkout-panel .content .field {
    display: block !important;
  }

  .checkout-panel .content .header {
    text-align: left !important;
    margin: 10px 0 !important;
    padding: 0 !important;
  }

  .checkout-panel .content .method {
    text-align: left !important;
    width: calc(100%) !important;
  }

  /* .checkout-panel .content .method .field {
    margin: 0 0 20px 0 !important;
    padding: 0 !important;
  } */

  .checkout-panel .content .time {
    width: calc(100%) !important;
    text-align: left !important;
  }

  .checkout-panel .content .info {
    width: calc(100%);
    text-align: left !important;
  }

  .checkout-panel .content .item-list {
    width: calc(100%) !important;
  }

  .checkout-panel .content .google-map {
    width: calc(100%) !important;
    position: relative !important;
    overflow: hidden !important;
  }

  .order-recall-sidebar {
    width: 290px !important;
    height: 100% !important;
  }

  .blockdisplay-if-small {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .hide-if-small {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .order-placed-panel .get-receipt-btn {
    display: none;
  }

  .menu-item {
    width: calc(100%) !important;
    margin: 5px 0px !important;
  }

  .goorder-online-itemDetail {
    position: relative !important;
    height: 100% !important;
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: rgb(255, 255, 255) !important;
    margin: 0 !important;
    padding: 0 !important;
    max-height: unset !important;

    /* border-radius: 16px !important; */
    /* overflow: hidden !important; */
  }

  .goorder-online-itemDetail .item-discription {
    font-size: 11px;
    line-height: 12px;
  }

  .goorder-online-itemDetail .item-image {
    width: 100%;
    max-height: 200px;
    margin: "0px 0";
  }

  .goorder-online-itemDetail .header {
    /* height: 70px !important; */
    margin: 0px !important;
  }

  .goorder-online-itemDetail .item-info {
    background-color: #f7f7f7;
    font-size: 22px !important;
    padding: 12px !important;
    line-height: 25px !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  }

  .goorder-online-itemDetail .item-name {
    font-size: 19px !important;
    margin: 0 !important;
    font-weight: 700 !important;
  }

  .goorder-online-itemDetail .item-option-name {
    font-size: 16px !important;
  }

  .goorder-online-itemDetail .item-list {
    /* max-height: calc(75vh - 290px) !important; */
    max-height: calc(100vh - 120px) !important;
    /* height: calc(100vh - 120px) !important; */
    overflow-y: auto !important;
    /* top: 50px !important; */
    position: relative !important;
  }

  .goorder-online-itemDetail .item-option {
    padding: 2px 35px !important;
  }

  .goorder-online-itemDetail .footer {
    margin: 0px !important;
    padding: 0px !important;
    height: 70px !important;

    bottom: 0 !important;
    position: fixed !important;
    left: 0px !important;
    right: 0px !important;
  }

  .goorder-online-itemDetail input {
    text-align: center !important;
  }

  .ui.modal {
    border-radius: unset !important;
  }
}

@media only screen and (max-width: 350px) {
  .checkout-panel .content {
    height: 100% !important;
    width: 300px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    display: block !important;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
}

.ui.inverted.dimmer .ui.loader:before {
  border-color: rgb(255 255 255 / 10%) !important;
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: #003162 transparent transparent !important;
}

.ui.page.modals.dimmer.transition.visible.active {
  padding: 0 !important;
}

.statistic .value {
  font-family: "Rubik", sans-serif !important;
}

.itemQty input {
  text-align: center !important;
}

