.login-modal-div .modal-content {
  background-color: #eff1f3;
  margin: auto;
  padding-top: 20px;
  border: 1px solid #888;
  width: 550px;
  border-radius: 5px;
}
.login-modal-div .modal-header .tabLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border-top: 2px solid transparent;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .modal-header .userLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border-top: 2px solid transparent;
  width: 33%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.ui.attached.segment {
  border: none;
  width: calc(100% - (1px * 2));
  margin: 0px;
  max-height: 700px;
  overflow-y: auto;
}
.login-modal-div .modal-header .tabActive {
  color: #555;
  border-top: 2px solid #003162;
  border-bottom: 3px solid #fff;
  background-color: #fff;
}
.login-register-div {
  border: 1px solid #ddd;
}
.login-register-div .active {
  color: #555;

  border-top: 2px solid #003162;
  border-bottom: 3px solid #fff;
  background-color: #fff;
}
.login-modal-div .modal-header input[type="radio"] {
  display: none;
}
.login-modal-div .modal-header label.tab-label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  padding-bottom: 15px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border: 1px solid transparent;
  border-bottom-width: 1px;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .modal-header #tab1:checked ~ #content1,
.login-modal-div .modal-header #tab2:checked ~ #content2 {
  display: block;
}
.login-modal-div form {
  margin-bottom: 14px;
}
input[type="color"],
input[type="file"],
input[type="hidden"],
input[type="image"],
input[type="radio"] {
  border: 0;
  border-radius: 0;
  padding: 0;
}
.modal-form-group {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
.modal-form-group .input-icon {
  opacity: 1;
  position: absolute;
  z-index: 1;
  left: 8px;
  top: 10px;
  color: #979faf;
  font-size: 26px;
}
.login-modal-div input[type="text"],
.login-modal-div input[type="email"],
.login-modal-div input[type="password"] {
  border: 1px solid #c2c7d0;
  border-top-color: rgb(194, 199, 208);
  border-right-color: rgb(194, 199, 208);
  border-bottom-color: rgb(194, 199, 208);
  border-left-color: rgb(194, 199, 208);
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(151, 159, 175, 0.1),
    inset 0 1px 15px rgba(151, 159, 175, 0.05);
  box-sizing: border-box;
  color: #39424e;
  display: inline-block;
  line-height: 1.5em;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 7px 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 100%;
}
.login-modal-div .modal-header label.tab-label:first-of-type {
  float: left;
}
.login-modal-div .modal-header label.tab-label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #888;
  border: 1px solid transparent;
  width: 50%;
  padding-bottom: 12px;
  font-size: 20px;
  border-bottom-width: 4px;
  background-color: #e9ebee;
}
.login-modal-div .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -5px;
}
.login-modal-div .modal-header .tabLabel:hover {
  color: #888;
  cursor: pointer;
}
.close-div {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: "#555555";
  margin-right: 10px;
  margin: 0 20px 20px 20px;
}
.login-modal-div .center {
  text-align: center;
}
.login-modal-div .modal-header {
  padding: 20px;
  border-radius: 5px;
}
.login-modal-div .white-bg {
  background-color: #fff;
}
.g_id_signin {
  display: inline-block !important;
  background: white !important;
  color: #c9453a !important;
  border-radius: 5px !important;
  border: thin solid #888 !important;
  white-space: nowrap;
}
span.icon {
  background: url("/identity/sign-in/g-normal.png") transparent 5px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
.social-divider {
  width: 80%;
  text-align: center;
  border-bottom: 1px solid #979faf;
  line-height: 0.1em;
  margin: 20px 0 20px;
  margin-left: 10%;
}
.g_id_signin {
  margin-top: "5px";
  margin-left: "20px";
}
.auth-input-field {
  text-align: left !important;
  font-size: 15px !important;
}
.ui.form .field > label {
  font-size: 15px !important;
}

@media only screen and (min-width: 1024px) {
  .checkoutUsderIcon {
    position: absolute;
    right: 100px;
  }
}
@media only screen and (min-width: 1481px) {
  .checkoutUsderIcon {
    position: absolute;
    right: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .login-modal-div {
    max-width: 600px;
  }
  .login-modal-div .modal-content {
    width: 360px;
    border-radius: 5px;
  }
  .login-modal-div .user-modal-content {
    width: 100%;
    border-radius: 5px;
  }
  .google_button {
    margin-left: 10px !important;
  }
  #rc-anchor-container {
    width: 300px;
  }
  .rc-anchor-normal {
    width: 270px !important;
  }
}
